<template>
	<div class="container">
		<template v-if="isMobile==0">
			<div class="header">
				<span>{{$t('aside.list')[3]}}</span>
				<!-- <router-link tag="div" class="btn" to="/robot">{{$t('aside.list')[2]}}</router-link> / <span>{{$t('robot.log')}}</span> -->
			</div>
			
			<div class="content">
				<div class="content-container">
					<div class="robot_log">
						<div class="head">
							<div class="item">
								<div class="title">{{$t('common.selectdate')}}</div>
								<el-date-picker
									v-if="isSmall=='normal'"
									:editable="false"
									class="datetime"
									v-model="dateValue"
									type="datetimerange"
									range-separator="-"
									:start-placeholder="$t('common.startdete')"
									:end-placeholder="$t('common.enddete')"
									value-format="timestamp"
									:clearable="false"
									@change="selectTime"
								/>
								<!-- 如果小屏分为两个日期时间选择器 -->
								<div class="datetime_box" v-else>
									<el-date-picker
										:editable="false"
										v-model="start_time"
										class="datetime_item left"
										type="datetime"
										:placeholder="$t('common.startdete')"
										value-format="timestamp"
										:picker-options="maxTime"
										:clearable="false">
									</el-date-picker>
									<span class="line">-</span>
									<el-date-picker
										:editable="false"
										v-model="end_time"
										class="datetime_item right"
										type="datetime"
										:placeholder="$t('common.enddete')"
										value-format="timestamp"
										:picker-options="minTime"
										:clearable="false">
									</el-date-picker>
								</div>
							</div>
							<div class="item">
								<div class="title">{{$t('common.exchange')}}</div>
								<!-- {{$t('common.exchange')}} -->
								<div class="select" @click="popExchange = true">
									<input v-model="exchangeValue" type="text" :placeholder="$t('common.select')" readonly class="inp" />
									<i class="iconfont icon-down"></i>
								</div>
							</div>
							<div class="item" v-if="web!='opensea'">
								<div class="title">{{$t('common.pair')}}</div>
								<!-- {{$t('common.pair')}} -->
								<div class="inp_box">
									<input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
									<i class="split"></i>
									<input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
								</div>
							</div>
							<div class="item" v-else>
								<div class="title">{{$t('common.opensea')}}</div>
								<div class="inp_box">
									<input v-model="yenUnit1" type="text" :placeholder="$t('common.opensea')" class="inp" />
								</div>
							</div>
							<div class="item">
								<div class="title">{{$t('common.search')}}</div>
								<!-- {{$t('common.search')}} -->
								<el-input
									class="search"
									:placeholder="$t('common.keyword')"
									suffix-icon="el-icon-search"
									v-model="searchValue"
								/>
							</div>
							<div class="btn" @click="handleSearch">{{$t('common.query')}}</div>
						</div>
						<div class="cont">
							<div class="cont_box">
								<template v-if="logList.length">
									<div class="list"
									v-infinite-scroll="load"
									:infinite-scroll-distance="10"
									:infinite-scroll-delay="400"
									:infinite-scroll-disabled="load_disabled">
										<template v-if="logList.length>0">
											<div class="item" v-for="(item,index) in logList" :key="index">
												<span class="time">{{item.time}}</span>
												<span class="exchange">{{item.web}}</span>
												<span class="unit">{{item.currency}}</span>

												<span class="text" v-html="item.content.replace(/[\n]/g,'<br/>')"></span>
											</div>
										</template>
									</div>
								</template>
								<el-empty v-else :image="emptyImg" :image-size="120" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template v-else-if="isMobile==1">
			<div class="content_phone">
				<div class="robot_log_phone">
					<div class="head">
						<el-input
							class="search"
							:placeholder="$t('common.keyword')"
							prefix-icon="el-icon-search"
							v-model="searchValue"
							type="search"
							@change="handleSearch2('keywords')"
						/>
						<!-- <div class="btn" @click="handleSearch">{{$t('common.query')}}</div> -->
					</div>
					<div class="cont">
						<div class="cont_box">
							<template v-if="logList.length">
								<div class="list"
								v-infinite-scroll="load"
								:infinite-scroll-distance="10"
								:infinite-scroll-delay="400"
								:infinite-scroll-disabled="load_disabled">
									<template v-if="logList.length>0">
										<div class="item" v-for="(item,index) in logList" :key="index">
											<div>
												<div class="label">{{$t('common.time')}}</div>
												<div class="value">{{item.time}}</div>
											</div>

											<div>
												<div class="label">{{$t('common.exchange')}}</div>
												<div class="value">{{item.web}}</div>
											</div>

											<div>
												<div class="label">{{$t('common.pair')}}</div>
												<div class="value">{{item.currency}}</div>
											</div>

											<div>
												<div class="label">{{$t('common.content')}}</div>
												<div class="value" v-html="item.content.replace(/[\n]/g,'<br/>')"></div>
											</div>
										</div>
									</template>
								</div>
							</template>
							<el-empty v-else :image="emptyImg" :image-size="120" />
						</div>
					</div>
				</div>
			</div>
		</template>
		<!-- 交易所选择 -->
		<exchange-dialog
			:show="popExchange"
			:web="web"
			@close="closeExchange"
			@select="handleSelect"
		/>
		
		<!-- 移动端-筛选条件弹窗 -->
        <van-action-sheet 
        v-model="filter_show" 
        :title="$t('common.filter')"
        :closeable="false">
			<div class="filter_form">
				<div class="item">
					<div class="title">{{$t('common.startdete')}}</div>
					<div class="text" @click="openDateTime('start')">
						<input v-model="vant_start_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.enddete')}}</div>
					<div class="text" @click="openDateTime('end')">
						<input v-model="vant_end_time" type="text" :placeholder="$t('common.select')" class="inp" readonly />
					</div>
				</div>
				<div class="item">
					<div class="title">{{$t('common.exchange')}}</div>
					<div class="text">
						<div class="select" @click="popExchange = true">
							<input v-model="vant_exchange_value" type="text" :placeholder="$t('common.select')" readonly class="inp" />
							<i class="iconfont icon-down"></i>
						</div>
					</div>
				</div>
				<div class="item" v-if="web!='opensea'">
					<div class="title">{{$t('common.pair')}}</div>
					<!-- {{$t('common.pair')}} -->
					<div class="inp_box">
						<input v-model="yenUnit1" type="text" :placeholder="$t('common.currency')" class="inp" />
						<i class="split"></i>
						<input v-model="yenUnit2" type="text" :placeholder="$t('common.currency')" class="inp" />
					</div>
				</div>
				<div class="item" v-else>
					<div class="title">{{$t('common.opensea')}}</div>
					<div class="inp_box">
						<input v-model="yenUnit1" type="text" :placeholder="$t('common.opensea')" class="inp" />
					</div>
				</div>
				<div class="foot">
					<div class="g_btn btn" @click="closeAction">{{$t('common.back')}}</div>
					<div class="g_btn btn submit" @click="handleSearch2">{{$t('common.query')}}</div>
				</div>

			</div>
        </van-action-sheet>
		<!-- 移动端-时间选择器 -->
		<van-popup v-model="dateTimeBox" round position="bottom">
			<van-datetime-picker
			ref="datetimePicker"
			v-model="currentDate"
			type="datetime"
        	:title="$t('common.selectTime')"
			:formatter="formatter"
			visible-item-count="4"
			@confirm="setTime"
			:min-date="min_date"
			:max-date="max_date"
			/>
		</van-popup>
	</div>
</template>

<style scoped="scoped" lang="stylus">
	@import 'robot.styl';
	@import 'log.styl';
</style>

<script>
	import { Loading } from 'element-ui';
			
	// import ExchangeDialog from '@/components/exchange.vue';
	import ExchangeDialog from '@/components/exchange_not_el.vue';
	import { mapGetters } from 'vuex';
	import bus from "../bus.js";
	import timeFormat from '@/utils/methApi/timeFormat'
	// api
	import { getLog } from '@/api/robot';
	export default ({
		name: 'robotlog',
		components: {
			ExchangeDialog,
		},
		data() {
			return {
				emptyImg: require('@/assets/images/loading.png'),
				
				logList: [],
				dateValue: [],
				start_time:'',
				end_time:'',
				exchangeValue: localStorage.getItem('exchange') || '',
				searchValue: null,
				yenUnit1: localStorage.getItem('yenUnit1') || '',
				yenUnit2: localStorage.getItem('yenUnit2') || '',
				// 交易所选择
				popExchange: false,
				web: localStorage.getItem('web') || '',

				page:1,
				load_disabled:false,

				isFirst:'1',//是否是第一次进入该页面

				// PC-无限滚动-固化变量
				pc_dateValue:[],
				pc_start_time:'',
				pc_end_time:'',
				pc_web:'',
				pc_yenUnit1:'',
				pc_yenUnit2:'',
				pc_searchValue:'',
				// 移动端
				filter_show:false,
				vant_start_time:'',
				vant_end_time:'',
				vant_exchange_value:'',
				vant_web:'',
				vant_yenUnit1:'',
				vant_yenUnit2:'',
				//时间选择器
				dateTimeBox:false,
				currentDate:new Date(),
				type:''//时间类型 开始时间/结束时间
			}
		},
		computed:{
			...mapGetters(['account','isSmall','isMobile']),
			// 开始时间
			maxTime() {
				return {
					disabledDate:(time)=>{
						if (this.end_time) { // 如果结束时间不为空，则小于结束时间
							return this.end_time < time.getTime()
						}
					}
				}
			},
			// 结束时间
			minTime() {
				return {
					disabledDate:(time)=>{
						if (this.start_time) { // 如果开始时间不为空，则结束时间大于等于开始时间
							return this.start_time> time.getTime()//||time.getTime() > Date.now()
						}
						// else {
						// 	return time.getTime() > Date.now()// 开始时间不选时，结束时间最大值为当天
						// }
					}
				}
			},
			
			// 移动端
			min_date(){
				var min_date
				if(this.type=='start'){
					min_date=new Date(0)
				}else{
					min_date=this.vant_start_time?new Date(this.vant_start_time.replace(/\-/g,'/')):new Date(0)
				}
				return min_date
			},
			max_date(){
				var max_date
				if(this.type=='start'){
					max_date=this.vant_end_time?new Date(this.vant_end_time.replace(/\-/g,'/')):new Date()
				}else{
					max_date=new Date()
				}
				return max_date
			},
		},
		created(){
			this.$nextTick(()=>{
				if(!!this.$route.query.web){
					this.exchangeValue=this.$route.query.exchange;
					this.web=this.$route.query.web;
					this.yenUnit1='';
					this.yenUnit2='';
					this.searchValue=this.$route.query.searchValue;
				}
				this.getLog();
			});

        	// 筛选弹窗-移动端判定
			bus.$on('filter',()=>{
				this.openFilter()
			})
		},
		methods: {
			// 时间日期选择区间
			selectTime(values){
				if(values){
					this.start_time=values[0]
					this.end_time=values[1]
				}
			},
			// 加载更多
			load(){
				// console.log('get-load')
				this.page++
				this.getLog();
			},
			getLog(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});
				if(this.isFirst=='1'){
					getLog({
						account:this.account,
						page:this.page,
						web:'',
						currency:'',
						start_time:'',
						end_time:'',
						keywords:'',
					}).then(res=>{
						// console.log(res)
						if(!!res&&res.length>0){
							res.forEach(item => {
								item.content=unescape(item.content.replace(/\\(u[0-9a-fA-F]{4})/gm, '%$1'))
							});
							this.logList.push(...res);
							// this.logList=res
						}else{
							this.load_disabled=true;
						}
						loading.close();
					}).catch(err=>{
						loading.close();
					})
				}else{
					// PC-无限滚动-固化变量-获取log
					var start_time,end_time;
					if(this.isSmall=='small'){
						// 日期没问题 时间选择时 结束时间比开始时间小
						if(this.pc_start_time>this.pc_end_time){
							start_time=this.pc_end_time?timeFormat(this.pc_end_time,'dateTime'):'';
							end_time=this.pc_start_time?timeFormat(this.pc_start_time,'dateTime'):'';
						}else{
							start_time=this.pc_start_time?timeFormat(this.pc_start_time,'dateTime'):'';
							end_time=this.pc_end_time?timeFormat(this.pc_end_time,'dateTime'):'';
						}
					}else{
						if(this.pc_dateValue[0]>this.pc_dateValue[1]){
							start_time=this.pc_dateValue&&this.pc_dateValue[1]?timeFormat(this.pc_dateValue[1],'dateTime'):'';
							end_time=this.pc_dateValue&&this.pc_dateValue[0]?timeFormat(this.pc_dateValue[0],'dateTime'):'';
						}else{
							start_time=this.pc_dateValue&&this.pc_dateValue[0]?timeFormat(this.pc_dateValue[0],'dateTime'):'';
							end_time=this.pc_dateValue&&this.pc_dateValue[1]?timeFormat(this.pc_dateValue[1],'dateTime'):'';
						}
					}

					var currency;
					if(this.web!='opensea'){
						currency=this.pc_yenUnit1&&this.pc_yenUnit2?this.pc_yenUnit1+'_'+this.pc_yenUnit2:''
					}else{
						currency=this.pc_yenUnit1
					}

					getLog({
						account:this.account,
						page:this.page,
						web:this.pc_web?this.pc_web:'',
						currency,
						start_time,
						end_time,
						keywords:this.pc_searchValue?this.pc_searchValue:'',
					}).then(res=>{
						// console.log(res)
						if(!!res&&res.length>0){
							res.forEach(item => {
								item.content=unescape(item.content.replace(/\\(u[0-9a-fA-F]{4})/gm, '%$1'))
							});
							this.logList.push(...res);
							// this.logList=res
						}else{
							this.load_disabled=true;
						}
						loading.close();
					}).catch(err=>{
						loading.close();
					})
				}
			},
			// 搜索
			handleSearch() {
				// console.log(123,this.searchValue)
				// 初始化
				this.page=1;
				this.load_disabled=false;
				this.logList=[];
				this.isFirst='0';

				// PC-无限滚动-固化变量
				this.pc_dateValue=JSON.parse(JSON.stringify(this.dateValue))
				this.pc_start_time=this.start_time
				this.pc_end_time=this.end_time
				this.pc_web=this.web
				this.pc_yenUnit1=this.yenUnit1
				this.pc_yenUnit2=this.yenUnit2
				this.pc_searchValue=this.searchValue
				
				// 搜索
				this.getLog();
			},
			// 交易所
			closeExchange() {
				this.popExchange = false;
			},
			handleSelect(data) {
				this.vant_exchange_value=this.exchangeValue = data.text;
				this.vant_web=this.web = data.web;
				this.popExchange = false;
			},
			
			// 打开筛选弹窗 - 移动端
			openFilter(){
				this.vant_start_time=this.start_time
				this.vant_end_time=this.end_time
				this.vant_exchange_value=this.exchangeValue
				this.vant_web=this.web
				this.vant_yenUnit1=this.yenUnit1
				this.vant_yenUnit2=this.yenUnit2

				this.filter_show=true
			},
			// 关闭筛选弹窗
			closeAction() {
				this.filter_show = false;
				// this.$nextTick(()=>{
				// 	this.form = {};
				// 	this.activeItem = null;
				// 	this.web=null;
				// })
			},
			// 打开时间选择器
			openDateTime(type){
				this.type=type
				if(type=='start'){
					this.currentDate=this.start_time||new Date()
				}else{
					this.currentDate=this.end_time||new Date()
				}
				this.dateTimeBox=true;
			},
			// 时间选择器视图
			formatter(type, val) {
				if (type === 'year') {
					return val + '年';
				}
				if (type === 'month') {
					return val + '月';
				}
				if (type === 'day') {
					return val + '日';
				}
				if (type === 'hour') {
					return val + '时';
				}
				if (type === 'minute') {
					return val + '分';
				}
				return val;
			},
			setTime(e){
				if(this.type=='start'){
					this.vant_start_time=timeFormat(e,'dateTime')
				}else{
					this.vant_end_time=timeFormat(e,'dateTime')
				}
				this.dateTimeBox=false
			},
			// 移动端-筛选确定
			handleSearch2(type) {
				if(type!='keywords'){
					this.start_time=this.vant_start_time
					this.end_time=this.vant_end_time
					this.exchangeValue=this.vant_exchange_value
					this.web=this.vant_web
					this.yenUnit1=this.vant_yenUnit1
					this.yenUnit2=this.vant_yenUnit2
				}
				// 初始化
				this.page=1;
				this.load_disabled=false;
				this.logList=[];
				this.isFirst='0';
				this.filter_show=false
				// console.log(this.searchValue)
				// 搜索
				this.getLog2();
			},
			// 移动端-获取日志
			getLog2(){
				const loading = Loading.service({
					target: '.main',
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					customClass: 'apploading',
				});

				var start_time,end_time;
				if(this.start_time&&this.end_time){
					var dateValue1=new Date(this.start_time?.replace(/\-/g,'/')).getTime()
					var dateValue2=new Date(this.end_time?.replace(/\-/g,'/')).getTime()
					if(dateValue1>dateValue2){
						start_time=this.end_time;
						end_time=this.start_time;
					}else{
						start_time=this.start_time
						end_time=this.end_time
					}
				}else{
					start_time=this.start_time
					end_time=this.end_time
				}

				var currency;
				if(this.web!='opensea'){
					currency=this.yenUnit1&&this.yenUnit2?this.yenUnit1+'_'+this.yenUnit2:''
				}else{
					currency=this.yenUnit1
				}
				

				getLog({
					account:this.account,
					page:this.page,
					web:this.web?this.web:'',
					currency,
					start_time,
					end_time,
					keywords:this.searchValue?this.searchValue:'',
				}).then(res=>{
					// console.log(res)
					if(!!res&&res.length>0){
						res.forEach(item => {
							item.content=unescape(item.content.replace(/\\(u[0-9a-fA-F]{4})/gm, '%$1'))
						});
						this.logList.push(...res);
						// this.logList=res
					}else{
						this.load_disabled=true;
					}
					loading.close();
				}).catch(err=>{
					loading.close();
				})
			},
		}
	})
</script>
